import React, { useState } from 'react';
import axios from 'axios';
import './KYC.css'
import { toast } from 'react-custom-alert';


export default function KYC({globaluuid,apilink}) {
    const [govtIdFront, setGovtIdFront] = useState(null);
    const [govtIdBack, setGovtIdBack] = useState(null);
    const [addressProofFront, setAddressProofFront] = useState(null);
    const [addressProofBack, setAddressProofBack] = useState(null);
    const [selfie, setSelfie] = useState(null);
  
    const handleFileChange = (e, setFile) => {
      setFile(e.target.files[0]);
      console.log(e.target.files[0])
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      if(!govtIdFront || !govtIdBack || !addressProofFront || !addressProofBack || !selfie){
      toast.warning('All fields are required');
      } else{
      const formData = new FormData();
      const filesList = [];
  
      formData.append('file', govtIdFront);
      const govtIdFrontFilename = await axios.post('https://salary.trading/api/upload', formData);
      filesList.push(govtIdFrontFilename.data.filename);

      formData.set('file', govtIdBack);
      const govtIdBackFilename = await axios.post('https://salary.trading/api/upload', formData);
      filesList.push(govtIdBackFilename.data.filename);
  
      formData.set('file', addressProofFront);
      const addressProofFrontFilename = await axios.post('https://salary.trading/api/upload', formData);
      filesList.push(addressProofFrontFilename.data.filename);
  
      formData.set('file', addressProofBack);
      const addressProofBackFilename = await axios.post('https://salary.trading/api/upload', formData);
      filesList.push(addressProofBackFilename.data.filename);
  
      formData.set('file', selfie);
      const selfieFilename = await axios.post('https://salary.trading/api/upload', formData);
      filesList.push(selfieFilename.data.filename);
      // const filesData = [govtIdFront,govtIdBack,addressProofFront,addressProofBack,selfie];
      const encodefilesData = JSON.stringify(filesList);
      axios.post(apilink+'/updatekycdata', {encodefilesData,globaluuid})
      .then(res => {   
      if(res.data){
      toast.success('Files uploaded successfully');
      } else {
      console.log('upload failed');
      }
          
      })
      .catch(err => console.log(err));
  
      // toast.success('Files uploaded successfully');
      }
    };
  return (
    <div>
      <div className="App">
        <h2>KYC Verification</h2>
        <h6>Upload Your Proof of Identity</h6>
        {/* <span>Salary Trader Requires a valid government issue ID {'(drivers license, passport, national ID)'}</span> */}
      <form className="kyc-form" onSubmit={handleSubmit}>
        {/* <h2>KYC Submission</h2> */}
        <div className='form-main'>
        <div className="form-group">
          <img src="upload.png" alt="" />
          <label>Government ID Front</label>
          <span>Upload the front side of your document {<br/>} Supports: JPG, PNG, PDF</span>
          <input type="file" onChange={(e) => handleFileChange(e, setGovtIdFront)} />
        </div>

        <div className="form-group">
        <img src="upload.png" alt="" />
          <label>Government ID Back</label>
          <span>Upload the back side of your document {<br/>} Supports: JPG, PNG, PDF</span>
          <input type="file" onChange={(e) => handleFileChange(e, setGovtIdBack)} />
        </div>

        <div className="form-group">
        <img src="upload.png" alt="" />
          <label>Address Proof Front</label>
          <span>Upload the front side of your document {<br/>} Supports: JPG, PNG, PDF</span>
          <input type="file" onChange={(e) => handleFileChange(e, setAddressProofFront)} />
        </div>

        <div className="form-group">
        <img src="upload.png" alt="" />
          <label>Address Proof Back</label>
          <span>Upload the back side of your document {<br/>} Supports: JPG, PNG, PDF</span>
          <input type="file" onChange={(e) => handleFileChange(e, setAddressProofBack)} />
        </div>

        <div className="form-group">
        <img src="upload.png" alt="" />
          <label>Selfie:</label>
          <input type="file" onChange={(e) => handleFileChange(e, setSelfie)} />
        </div>

        </div>

        <button type="submit" className="submit-btn">Submit</button>
      </form>
    </div>
    </div>
  )
}
