const tutorialList = [
    {
      title: "Step 1: Sign Up",
      image: "SSS1.jpg",
      contents: `
Visit the SalaryTrade website: www.salary.trading

Sign up with your details:

First Name
Last Name
Username
Password
Email
Mobile Number
City
Country


OTP Verification: You will receive an OTP on your registered mobile number. Enter the OTP to verify your account.
      `
    },
    {
      title: "Step 2: KYC Verification",
      image: "SSS2.jpg",
      contents: `
Upload National Government ID: Provide the front and back of your ID.


Address Proof: Upload any utility bill or government document showing your address.


Selfie: Take a selfie for identity verification.


Approval: Our team will review your documents. Once approved, you will get full access to the platform.

      `
    },
    {
      title: "Step 3: Understanding the Trading Session",
      image: "SSS3.jpg",
      contents: `
Trading Hours: The trading session starts at 12:00 PM Indian Standard Time (IST).

Insights Submission:

You have 15 opportunities to submit insights every 30 minutes starting from 12:00 PM IST.
Each submission window lasts 1 minute, starting at the beginning of each 30-minute interval.
      `
    },
    {
      title: "Step 4: Submitting Your Insights",
      image: "SSS4.jpg",
      contents: `
Enter Price Insight: Enter the price you predict for the next 30 minutes.

Submit Insight: Click the "Up" or "Down" button based on your prediction within the 1-minute time limit.

      `
    },
    {
      title: "Step 5: Track Your Earnings and Analysis",
      image: "SSS5.jpg",
      contents: `
Daily, Weekly, and Monthly Reports: Track your earnings and performance in the Reports section.


Get Promotions Weekly: Get promotions for your accuracy and consistency. Earn up to $2000 per month.


Trading Rules and Guidelines
Promotion: Achieved by having a correct trading session at least 3 days in a week.

Demotion: If you have 3 incorrect trading sessions in a week, you will be demoted and will lose an amount from your earnings wallet equivalent to your level.

Trading Session: Missing a trading session or having one wrong session in a week leads to demotion.

Earnings: You need to be correct at least 12 out of 15 times in a day to earn for that day.

Withdrawal: Requests can be placed anytime with a minimum requirement of $100. Withdrawals take up to 7 days.




      `
    }
  ];
  
  export default tutorialList;
  