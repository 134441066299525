import React, { useState } from 'react';
import './FAQ.css';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';


const FAQ = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const faqData = [
        {
            question: "What is SalaryTrader?",
            answer: "SalaryTrader is a trading platform where users can earn fixed income without the need for deposits or investments."
          },
          {
            question: "How does SalaryTrader work?",
            answer: "SalaryTrader allows users to predict market movements and earn money based on their accuracy and consistency in trading."
          },
          {
            question: "What is KYC verification, and why is it required?",
            answer: "KYC (Know Your Customer) verification is a mandatory process for verifying the identity and address of users. It ensures the security of the platform and compliance with regulatory requirements."
          },
          {
            question: "What documents are required for KYC verification?",
            answer: "Users need to upload their National Government ID (Front and Back), Address Proof (Utility bill or government-issued address proof), and a Selfie for KYC verification."
          },
          {
            question: "How long does KYC verification take?",
            answer: "KYC verification typically takes up to 48 hours. Users will receive a notification email once their KYC is approved."
          },
          {
            question: "What happens if my KYC is rejected?",
            answer: "If your KYC is rejected, you will receive an email explaining the reasons for rejection. You can then re-upload the necessary documents for re-evaluation."
          },
          {
            question: "How secure is the personal information provided for KYC?",
            answer: "SalaryTrader takes the security of users' personal information seriously. All documents and data are stored securely and used solely for identity verification purposes."
          },
          {
            question: "How will I know when my KYC is approved?",
            answer: "You will receive an email notification once your KYC is successfully approved. Additionally, you can check your KYC status on your user dashboard."
          },
          {
            question: "What are the rules for trading on SalaryTrader?",
            answer: "Traders must achieve three consecutive correct trading sessions to advance to the next earning level. Promotion occurs every Friday if the trader has at least three correct trading days in the week, and demotion may occur for missing 3 day trading session or providing incorrect predictions."
          },
          {
            question: "What is the timing for submitting insights on SalaryTrader?",
            answer: "Insights can be submitted every 30 minutes starting from 12 PM Indian Standard Time (IST). Users have 1 minute to submit their analysis after the start of each 30-minute interval."
          },
          {
            question: "When does the trading session start on SalaryTrade?",
            answer: "The trading session on SalaryTrade starts at 12 : 00 PM Indian Standard Time (IST). You will have the opportunity to submit your insights every 30 minutes throughout the trading day. Below are the converted local times for different countries: \n\n New York, USA (EST): 1 : 30 AM\n London, UK (GMT): 6 : 30 AM\n Berlin, Germany (CET): 7 : 30 AM\n Dubai, UAE (GST): 10 : 30 AM\n Singapore (SGT): 2 : 30 PM\n Sydney, Australia\n(AEST): 4 : 30 PM\nTokyo, Japan (JST): 3 : 30 PM\nBeijing, China (CST): 2 : 30 PM"
          },
          {
            question: "How many insights can I submit in a day on SalaryTrader?",
            answer: "Users can submit a total of 15 insights in a day on SalaryTrader, with each insight opportunity occurring every 30 minutes starting from 12 PM IST."
          },
          {
            question: "What if I provide incorrect analysis or miss some insights?",
            answer: "To earn for the day on SalaryTrader, users must provide at least 12 correct Analysis."
          },
          {
            question: "Can I edit or change my insights after submission?",
            answer: "Once an insight is submitted on SalaryTrader, it cannot be edited or changed. Ensure to provide your analysis accurately within the given time frame."
          },
          {
            question: "How much can I earn on SalaryTrader?",
            answer: "Earnings on SalaryTrader vary based on the trading level reached. Traders can earn from $1 to $50 per correct prediction, depending on their level within the platform."
          },
          {
            question: "What is the earning table on SalaryTrader?",
            answer: " Level 1: $1 \n Level 2: $2\n Level 3: $2\n Level 4: $2\n Level 5: $5\n Level 6: $5\n Level 7: $5\n Level 8: $5\n Level 9: $5\n Level 10: $10\n Level 11: $15\n Level 12: $20\n Level 13: $30\n Level 14: $40\n Level 15: $50"
          },
          {
            question: "How do I withdraw my earnings from SalaryTrader?",
            answer: "You can withdraw your earnings through bank transfer, UPI, PayPal (for users in India), and PayPal and cryptocurrencies (USDT, BNB) for users in other countries."
          },
          {
            question: "How does the earning process work on SalaryTrader?",
            answer: "To earn on SalaryTrader, users need to provide their valuable insights into market movements every 30 minutes. Starting from 12 PM Indian Standard Time (IST), users have the opportunity to submit their analysis every 30 minutes for a total of 15 insights in a day. Users have 1 minute to submit their analysis after the start of each 30-minute interval. To earn for the day, users must provide correct predictions at least 12 out of the 15 times they submit their insights."
          },
          {
            question: "How often are earnings credited to my account?",
            answer: "Earnings are credited to your SalaryTrader account daily, based on the accuracy of your insights provided throughout the day."
          },
          {
            question: "When can I place a withdrawal request?",
            answer: "You can place a withdrawal request at any time, but the minimum required amount is $100."
          },
          {
            question: "How long does it take to process a withdrawal?",
            answer: "Withdrawal requests typically take up to 7 days to process."
          },
          {
            question: "What withdrawal methods are available?",
            answer: "For users in India: Bank Transfer, UPI, PayPal. For users in other countries: PayPal, Cryptocurrencies (USDT, BNB)."
          },
          {
            question: "Is there an incentive bonus?",
            answer: "Yes, an incentive bonus is awarded to the top 10 traders of the month on the leaderboard."
          },
          {
            question: "How can I contact SalaryTrader for further questions?",
            answer: "For additional questions, you can reach out to us at info@salarytrader.com or create a ticket from the help section. Our team will respond to your inquiries within 24 to 48 hours."
          }
    ];

    return (
        <div className="faq-container">
            <h1 className="faq-title">FAQs</h1>
            <h4 className="faq-subtitle">Answers to Frequently Asked Questions</h4>
            <div className="faq-list">
                {faqData.map((item, index) => (
                    <div className={`faq-item ${activeIndex === index ? 'active' : ''}`} key={index} onClick={() => toggleAccordion(index)}>
                        <div className="mainfaq">
                        <div className="faq-question">{item.question}
                        </div>
                        <div className="faq-space"></div>
                        <div className="expand-faq">
                            {activeIndex === index ?(<RemoveIcon/>):(<AddIcon/>)}
                        </div>

                        </div>
                        <div className="faq-answer">{item.answer}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FAQ;
