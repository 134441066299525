import React, { useEffect } from 'react'
import './Profile.css'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import axios from 'axios';

export default function Profile({globaluuid, apilink}) {
  // var apilink = 'https://test.projaat.com/api';
  // var apilink = 'http://localhost:8080/api';
  // var apilink = 'http://192.168.0.105:8080/api';

  const [myName,setmyName] = React.useState('');
  const [fName,setFName] = React.useState('');
  const [lName,setLName] = React.useState('');
  const [email,setEmail] = React.useState('');
  const [mobile,setMobile] = React.useState('');
  const [city,setCity] = React.useState('');
  const [country,setCountry] = React.useState('');
  // const [password,setPassword] = React.useState('');
  // eslint-disable-next-line
  const [userid,setuserid] = React.useState('');
  const [myLevel,setmyLevel] = React.useState('');
  // eslint-disable-next-line
  const [levelDate,setlevelDate] = React.useState('');
  // eslint-disable-next-line
  const [predictions,setPredictions] = React.useState('');
  // eslint-disable-next-line
  const [wallet,setWallet] = React.useState(0);
  const [kyc,setKyc] = React.useState(0);

  const handlelogout = () => {
    localStorage.removeItem('userId');
    localStorage.removeItem('uuid');
    window.location='/';
  };
  const editpass = () => {
    document.getElementById('pass').type = "text";
    document.getElementById('pass').readOnly = false;
    document.getElementById('edit').style.display = "none";
    document.getElementById('change').style.display = "unset";
  };
  const changepass = () => {
    const pass = document.getElementById('pass').value
    // const alphanumericRegex = /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]{8,20}$/;
    const alphanumericRegex = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+~\-=`{}[\]:";'<>?,./])[a-zA-Z0-9!@#$%^&*()_+~\-=`{}[\]:";'<>?,./]{8,20}$/;

    if (!alphanumericRegex.test(pass)) {
      alert('Password must be alphanumeric and 8 characters long and 12 characters max');
    } else {

      axios.post(apilink+'/updatepass', {pass,globaluuid})
              .then(res => {
                console.log(res.data);
              })
              .catch(err => console.log(err));
      document.getElementById('pass').readOnly = true;
      document.getElementById('pass').type = "password";
      document.getElementById('edit').style.display = "unset";
      document.getElementById('change').style.display = "none";
    }
  };

  console.log(globaluuid)
  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  useEffect(()=> {
  if(!myName){
  axios.post(apilink+'/level', {globaluuid})
      .then(res => {
        setmyName(res.data.username);
        setuserid(res.data.ID);
        setmyLevel(res.data.status);
        setFName(res.data.first);
        setLName(res.data.last);
        setEmail(res.data.email);
        setMobile(res.data.mobile);
        setCity(res.data.city);
        setCountry(res.data.country);
        // setPassword(res.data.password);
        setWallet(res.data.wallet);
        setKyc(res.data.kyc);
    console.log( 'AA' +res)

        document.getElementById('pass').value = res.data.password;
        if(res.data.resettime <= 0){
          setlevelDate('no data');
        } else {
          setlevelDate(formatDate(new Date(res.data.resettime)));
        }
        const globalData = JSON.parse(res.data.predictions);
              const parseData = (globalData) => {
                const sortedDates = Object.keys(globalData).sort((a, b) => new Date(b.split("/").reverse().join("-")) - new Date(a.split("/").reverse().join("-")));
                
              return sortedDates.map(date => ({
                date,
                results: Object.values(globalData[date]).map(entry => entry.result)
              }));
              };

              const checkResults = (parseData) => {
                const last5Days = parseData.slice(0,5);
                let daysWith12TrueResults = 0;

                for (const day of last5Days) {
                  const trueResults = day.results.filter(result => result === true);
                  if (trueResults.length >= 12) {
                    daysWith12TrueResults++;
                  }
                }
                return daysWith12TrueResults;
              };
              const parsedData = parseData(globalData);
              const hasThreeDaysWith12TrueResults = checkResults(parsedData);


        setPredictions(hasThreeDaysWith12TrueResults)      
      })
      .catch(err => console.log(err));
    }
  })
  return (
    <div style={{backgroundColor:'#121212',height:'100vh'}}>
      <div className="topbar-profile">
        <ArrowBackIcon onClick={()=> window.location="/"} className='profile-item-icon'/>
        <div className="pspace"></div>
      </div>
      <div className="profile-page">
      <span className='title-profile'>My Account</span>
      
      <div style={{display:'flex',justifyContent:'space-between',width:'90%'}}>
      <div style={{display:'flex',width:'90%',flexDirection:'column'}}>
        <span className='text-profile'>{myName}</span>
      {/* <div style={{display:'flex',width:'90%',alignContent:'center'}}>
      <span className='userid-profile'>Wallet :</span>
      <span className='userid-txt-profile'>{wallet}</span>
      </div> */}
        </div>
        <div className='inn-profile'>
      {/* <img className='profile-icon' src="user(11).png" alt="" /> */}
      </div>
      </div>
      <div className="level-profile">
        <div className="levelA">
            <img src="https://cdn-icons-png.flaticon.com/128/1634/1634156.png" alt="" />
            <span className='level-title'>My Level</span>
            <div className="ppspace"></div>
            {/* <span className='time-level'>{levelDate}</span> */}
        </div>
        <div className="levelB">
          <span>Level {myLevel}</span>
          {/* <span>{predictions}/5 Predictions</span> */}
        </div>
        <div className="levelC">
          <div style={{width: myLevel*6.66 + '%'}}></div>
        </div>
        <div className="levelD">
          <span>0</span>
          <span>15</span>
        </div>
      </div>
      {/* <span className="statusq">What are Levels?</span> */}

      <div style={{display:'flex',borderColor:kyc === 'true'?'#00ff66':'#ffcc00'}} className="kyc-div">
                <img src={kyc === 'true'?"https://cdn-icons-png.flaticon.com/128/9458/9458915.png":"https://cdn-icons-png.flaticon.com/128/8213/8213126.png"} alt="" />
                <span style={{color:kyc === 'true'?'#00ff66':'#ffcc00'}}>{kyc === 'pending'?'KYC Submitted Please wait for Verification': kyc === 'false'?'KYC Not Verified':'KYC Verified'}</span>
                <div style={{width:'100%'}}></div>
                {kyc === 'false'&& <button onClick={()=> window.location="/kyc"}>Verify Now</button>}
                
              {/* {account ?("Login"):("Sign Up")} */}
              </div>
      </div>
      <div className="profile-opt">
        <span className='opt-title'>First Name</span>
        <span className='opt-subtitle'>{fName}</span>
      </div>
      <div className="opt-line"></div>
      <div className="profile-opt">
        <span className='opt-title'>Last Name</span>
        <span className='opt-subtitle'>{lName}</span>
      </div>
      <div className="opt-line"></div>
      <div className="profile-opt">
        <span className='opt-title'>Email</span>
        <span className='opt-subtitle'>{email}</span>
      </div>
      <div className="opt-line"></div>
      <div className="profile-opt">
        <span className='opt-title'>Mobile Number</span>
        <span className='opt-subtitle'>{mobile}</span>
      </div>
      <div className="opt-line"></div>
      <div className="profile-opt">
        <span className='opt-title'>City</span>
        <span className='opt-subtitle'>{city}</span>
      </div>
      <div className="opt-line"></div>
      <div className="profile-opt">
        <span className='opt-title'>Country</span>
        <span className='opt-subtitle'>{country}</span>
      </div>
      <div className="opt-line"></div>
      <div className="profile-opt">
        <span className='opt-title'>Password</span>
        <div>
        {/* <span className='opt-subtitle'>********</span> */}
        <input id='pass' className='opt-pass' type="password" readOnly="readonly"/>
        <EditIcon id='edit' onClick={editpass} style={{color:'white',marginLeft:'20px',display:'unset'}}/>
        <CheckIcon id='change' onClick={changepass} style={{color:'white',marginLeft:'20px',display:'none'}}/>
        </div>
      </div>
      <div className="opt-line" style={{marginBottom:'10px'}}></div>
      <div className="optdivbtn">
        <button onClick={handlelogout}>Logout</button>
      </div>
    </div>
  )
}
