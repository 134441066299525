import React , {useEffect,useState} from 'react'
import './Timeline.css'
import axios from 'axios'


export default function Timeline({globaluuid, apilink}) {

  // var apilink = 'https://test.projaat.com/api';
  // var apilink = 'http://localhost:8080/api';
  // var apilink = 'http://192.168.0.105:8080/api';

  const supertime = ['12:00','12:30','01:00','01:30','02:00','02:30','03:00','03:30','04:00','04:30','05:00','05:30','06:00','06:30','07:00']
  const [superKeys,setsuperKeys] = useState('');
  const [superValues,setsuperValues] = useState('');
  // const UniDate = new Date('2024-05-25T07:40:17.728Z');
  const UniDate = new Date();
  const UniHourset = UniDate.toLocaleTimeString([], { hour: '2-digit' , hour12: true}).replace(/ am| pm| AM| PM/i, '');
  const UniHours = UniHourset === '00'?'12':UniHourset;
  const UniMin = (UniDate.getMinutes() >= 30?'30':'00');
  const UniMinu = UniDate.toLocaleTimeString([], { minute: '2-digit' , hour12: true}).replace(/ am| pm| AM| PM/i, '');
  const SetUni = (UniHours.length === 1?'0'+UniHours : UniHours) + ':' + (UniMin.length === 1?'0'+UniMin : UniMin);

  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };


  // const currentDate = new Date().toLocaleDateString();
  const currentDate = formatDate(new Date());
  const [PredictionData, setPredictionData] = useState({});

  useEffect(() => {

    axios.post(apilink+'/timeline', {globaluuid})
            .then(res => {
              if ( res.data && res.data[currentDate] && Object.keys(res.data[currentDate]).length > 0) {
                if(superValues.length === 0){
              setPredictionData(res.data[currentDate]);
              setsuperKeys(Object.keys(PredictionData).map(entry => entry));
              setsuperValues(Object.values(PredictionData).map(entry => entry.result));
            }
              } 
            })
              .catch(err => console.log(err));

  }, [globaluuid,apilink,currentDate,superKeys,superValues,PredictionData]);

            
    // const timelineItems = [
    //     { time: "12:00", content: "div-red" },
    //     { time: "1:30", content: "div-green" },
    //     { time: "2:45", content: "div-red" },
    //     { time: "3:45", content: "div-red" },
    //     { time: "4:30", content: "div-green" },
    //     { time: "5:45", content: "div-green" },
    //     { time: "6:00", content: "div-green" },
    //     { time: "7:00", content: "div-green" },
    //     { time: "7:00", content: "div-green" },
    //     { time: "7:00", content: "div-green" },
    //     { time: "7:00", content: "div-green" },
    //     { time: "7:00", content: "div-green" },
    //   ];

  
  return (

    <div>
      {/* <div className="line-item">
        <span>{superValues[1]}</span>
      </div> */}
      <div className="timeline">
        {Array.from(Array(15), (e, i) => {
          return <div style={{display:'flex',alignItems:'center',flexGrow:window.screen.width > 600 ? '1' : '0'}}>
          {/* <div className='lineline'></div> */}
  <div className="line-item">
      <span>
      {supertime[i] + ' pm'}
      {/* {UniMinu*(window.screen.width < 600?60:100)/(UniMinu <= 30?30:60)} */}
      </span>
      <div className={superKeys.includes(supertime[i]+ ' pm') ?  (superValues[superKeys.indexOf(supertime[i]+ ' pm')] ? ('div-green'):('div-red')):('div-grey')}>
          
      </div>
  </div>
  <div id={'line'+i} className='lineline'>
    <div className='inline' style={{backgroundColor: UniDate.getHours() <= 19 && UniDate.getHours() >= 11? (i <= supertime.indexOf(SetUni)?'#0094fd':'rgb(70, 70, 70)'):('#0094fd'),width: UniHours === supertime[i].split(':')[0] && UniMin === supertime[i].split(':')[1] && UniMinu*(window.screen.width < 600?60:100)/(UniMinu <= 30?30:(UniMinu>29 && UniMinu<46?90:60))+'px'}}>
    {/* <div className='inline' style={{backgroundColor: UniDate.getHours() <= 19? (i <= supertime.indexOf(SetUni)?'#0094fd':'rgb(70, 70, 70)'):('#0094fd'),width: UniHours === supertime[i].split(':')[0] && UniMin === supertime[i].split(':')[1] && UniMinu*(window.screen.width < 600?60:100)/(UniMinu <= 30?30:(UniMinu>29 && UniMinu<46?0:0))+'rem'}}> */}
    <div style={{display: UniHours === supertime[i].split(':')[0] && UniMin === supertime[i].split(':')[1] && 'block'}} className='tracker'></div>
    </div>
  
  </div>

  </div>
        })}
                <div className='linelinex'></div>
      </div>


      {/* <div className="timeline">
        {Object.entries(PredictionData).map(([time, prediction], index) => (
          
            <div style={{display:'flex',alignItems:'center',flexGrow:window.screen.width > 600 ? '1' : '0'}}>
                <div className='lineline'></div>
        <div className="line-item">
            <span>
            {time}
            </span>
            <div className={prediction.result ? ('div-green'):('div-red')}>
                
            </div>
        </div>
        </div>
        ))}
                <div className='linelinex'></div>
      </div> */}
    </div>



  )
}

