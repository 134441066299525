// import logo from './logo.svg';
import './App.css';
import Topbar from './components/homepage/Topbar';
// import Graph from './components/homepage/Graph';
import Prediction from './components/homepage/Prediction';
import Login from './components/register/Login';
import Navbar from './components/extra/Navbar';
import Sidebar from './components/homepage/Sidebar';
import Timeline from './components/homepage/Timeline';
import Withdrawal from './components/pages/Withdrawal';
import Profile from './components/pages/Profile';
import Tradingview from './components/homepage/Tradingview';
import Reports from './components/homepage/Reports';
import FAQ from './components/homepage/FAQ';
import Tutorial from './components/homepage/Tutorial';
import Contact from './components/homepage/Contact';
import KYC from './components/homepage/KYC';
import Leaderboard from './components/homepage/Leaderboard';
import React ,{useEffect} from 'react';
import { ToastContainer } from 'react-custom-alert';
import 'react-custom-alert/dist/index.css';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
// import { useRef } from "react";


function usePageViews() {
  let location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location]);
}

function App() {
  const [isClick,setisClick] = React.useState(false);
  const [isNew,setisNew] = React.useState(true);
  const [isSidebar,setSidebar] = React.useState(true);
  const [showPage,setshowPage] = React.useState('home');
  const [globalLogin,setglobalLogin] = React.useState(localStorage.getItem('userId'));
  const [globaluuid,setglobaluuid] = React.useState(localStorage.getItem('uuid'));
  console.log('log'+(window.location.pathname))

  var apilink = 'https://salary.trading/api';
  // var apilink = 'https://test.projaat.com/api';
  // var apilink = 'http://localhost:8080/api';
  // var apilink = 'http://192.168.0.105:8080/api';

  // const globalLogin = localStorage.getItem('userId');

  // const loginRef = useRef();


  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.src = 'https://zovidree.com/tag.min.js';
  //   script.setAttribute('data-zone', 7697245);
  //   document.head.appendChild(script);

  //   return () => {
  //     document.head.removeChild(script);
  //   };
  // }, []);


  useEffect(() => {
    ReactGA.initialize('G-CPJBN62NFJ');  // Replace with your Google Analytics tracking ID
  }, []);

  usePageViews();
  
  const handleClick = event => {
    setisNew(true);
    if (isClick === true) {
      setisClick(false);
    } else {
      setisClick(true);
    }
  }
  const handleSidebar = event => {
    setSidebar(!isSidebar);
  }

  useEffect(()=>{
    if(window.location.pathname === '/reports' && window.location.pathname === '/help' && window.location.pathname === '/profile' && window.location.pathname === '/tutorial' && globalLogin?false:true){
      document.body.classList.add('reports-scroll');
      document.documentElement.classList.add('reports-scroll');
    } else {
      document.body.classList.remove('reports-scroll');
      document.documentElement.classList.remove('reports-scroll');
    }
    // if(!!localStorage.getItem('uuid') || window.location.pathname === '/'){
    //   document.body.classList.add('reports-scroll');
    //   document.documentElement.classList.add('reports-scroll');
    // } else {
    //   document.body.classList.remove('reports-scroll');
    //   document.documentElement.classList.remove('reports-scroll');
    // }
    console.log('logi'+( globalLogin === null))

    if(window.location.pathname === '/withdrawal'){
      if(globalLogin){
        setshowPage('withdrawal');
      }else{
      window.location.href = '/platform';
      }
    } else if(window.location.pathname === '/profile') {
      if(globalLogin){
        setshowPage('profile');
      }else{
      window.location.href = '/platform';
      }
    } else if(window.location.pathname === '/reports') {
      if(globalLogin){
        setshowPage('reports');
      }else{
      window.location.href = '/platform';
      }

    }else if(window.location.pathname === '/help') {
      if(globalLogin){
        setshowPage('help');
      }else{
      window.location.href = '/platform';
      }

    }else if(window.location.pathname === '/tutorial') {
      if(globalLogin){
        setshowPage('tutorial');
      }else{
      window.location.href = '/platform';
      }

    }else if(window.location.pathname === '/contact') {
      if(globalLogin){
        setshowPage('contact');
      }else{
      window.location.href = '/platform';
      }

    }else if(window.location.pathname === '/login') {
      setshowPage('login');

    }else if(window.location.pathname === '/kyc') {
      if(globalLogin){
        setshowPage('kyc');
      }else{
      window.location.href = '/platform';
      }

    }else if(!globalLogin && window.location.pathname !== '/login') {
      // setshowPage('platform');
      window.location.href = '/platform';

    }
     else {
      setshowPage('home');
    }
  },[setshowPage,globalLogin])
  return (
    <>
    <Helmet>
        <title>Salary Trader</title>
        <meta name="description" content="Building tomorrows communities through technology today." />
        <meta property="og:title" content="Salary Trader - Trade Now" />
        <meta property="og:description" content="Building tomorrows communities through technology today." />
        <meta property="og:image" content="https://salary.trading/logo512.png" />  {/* Replace with your image URL */}
        <meta property="og:url" content="https://salary.trading" />  {/* Replace with your website URL */}
      </Helmet>
    {/* <button id="fullscreenButton">Fullscreen</button> */}
    {globalLogin && showPage !== 'platform' ? (
  showPage === 'withdrawal' ? (
    <Withdrawal globaluuid={globaluuid} apilink={apilink} />
  ) : showPage === 'help' ? (
    <FAQ />
  ) : showPage === 'profile' ? (
    <Profile globaluuid={globaluuid} apilink={apilink} />
  ) : showPage === 'reports' ? (
    <Reports globaluuid={globaluuid} apilink={apilink} />
  ) : showPage === 'tutorial' ? (
    <Tutorial/>
  ) : showPage === 'kyc' ? (
    <KYC globaluuid={globaluuid} apilink={apilink}/>
  ) : showPage === 'contact' ? (
    <Contact apilink={apilink}/>
  ) : (
    <div className='trading-layout'>
      <Sidebar isSidebar={isSidebar} />
      <div className="in-trading-layout">
        <Topbar
          globaluuid={globaluuid}
          handleSidebar={handleSidebar}
          isSidebar={isSidebar}
          handleClick={handleClick}
          isClick={isClick}
          apilink={apilink}
          visible="true"
        />
        <Timeline items={5} globaluuid={globaluuid} apilink={apilink} />

        <div style={{
          width: '99vw',
          // height: '100vh',
          flexDirection: window.screen.width > 600 ? 'row' : 'column',
          display: 'flex',
          overflow: 'visible',
          marginBottom: '10px',
          border: 'none',
          position: window.screen.width > 600 && 'relative',
          left: isSidebar && '-22%'
        }}>
          {window.screen.width > 600 ?
          <div style={{display:'flex',flexDirection:'column',width:'30%'}}>
          <Prediction
            globaluuid={globaluuid}
            setglobaluuid={setglobaluuid}
            isClick={isClick}
            setisClick={setisClick}
            isNew={isNew}
            setisNew={setisNew}
            apilink={apilink}
            visible="true"
          />
          <Leaderboard
            apilink={apilink}
            globaluuid={globaluuid}
          />

          </div>:
          <Prediction
          globaluuid={globaluuid}
          setglobaluuid={setglobaluuid}
          isClick={isClick}
          setisClick={setisClick}
          isNew={isNew}
          setisNew={setisNew}
          apilink={apilink}
          visible="true"
        />

          }
          <Tradingview isSidebar={isSidebar} visible="true" />
          {window.screen.width < 600 &&
          <Leaderboard
          apilink={apilink}
          globaluuid={globaluuid}
        />
          }
        </div>

        <Login setglobalLogin={setglobalLogin} setglobaluuid={setglobaluuid} visible="false" apilink={apilink} />
      </div>
    </div>
  )
) : (
  // (showPage !== 'platform' &&
  showPage === 'login'?(
  <>
    <Navbar />
    <Login setglobalLogin={setglobalLogin} setglobaluuid={setglobaluuid} apilink={apilink} visible="true" />
  </>
  ):(
    // window.location.href = '/platform'
    null
  )
// )
)}

    <ToastContainer floatingTime={5000} toastStyle={{backgroundColor:'#000000',color:'white',boxShadow:'none',border:'2px solid #0094fd',fontFamily:'Box',fontSize:'14px'}} />
    
    </>
    
  );
}

export default App;
