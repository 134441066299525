import React from 'react'
import './Sidebar.css'
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import WalletIcon from '@mui/icons-material/Wallet';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
// import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
// import NotificationsIcon from '@mui/icons-material/Notifications';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import QuizIcon from '@mui/icons-material/Quiz';
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';

export default function Sidebar({isSidebar}) {
  const [dropdown,setDropdown] = React.useState(false);
  const handlelogout = () => {
    localStorage.removeItem('userId');
    localStorage.removeItem('uuid');
    window.location.reload();
  };
  return (
    <div  className={`Sidebar ${isSidebar ? '' : 'closed'}`}>
        <div className="sidebar-header">
            {/* <img src="stlogo.png" alt="" /> */}
            <img src="SalaryNewLogo.png" alt="" />
            {/* <h2>Salary Trader</h2> */}
        </div>
        
      <div className="sidebar-item s-item1">
        <SpaceDashboardIcon className='sidebar-item-icon'/>
        <span>Dashboard</span>
      </div>
      <div onClick={()=> window.location="/withdrawal"} className="sidebar-item s-item2">
      <WalletIcon className='sidebar-item-icon'/>
        <span>Withdrawal</span>
      </div>
      <div onClick={()=> window.location="/reports"} className="sidebar-item s-item3">
      <HistoryToggleOffIcon className='sidebar-item-icon'/>
        <span>Reports</span>
      </div>
      {/* <div className="sidebar-item s-item4">
      <NotificationsIcon className='sidebar-item-icon'/>
        <span>Notifications</span>
      </div> */}
      <div onClick={()=> window.location="/profile"} className="sidebar-item s-item5">
      <AccountCircleIcon className='sidebar-item-icon'/>
        <span>My Account</span>
      </div>
      <div className="sidespace"></div>
      <div onClick={()=> setDropdown(!dropdown) } className="sidebar-item s-item6">
      <SettingsIcon className='sidebar-item-icon'/>
        <span>Help</span>
      </div>
        {dropdown && 
        (<div className="help-option">
          <p style={{display:'flex',alignItems:'center',cursor:'pointer'}} onClick={()=> window.location="/tutorial"}>
            <AppRegistrationIcon className='sidebar-item-icon'/>
             Tutorial
             </p>
          <p style={{display:'flex',alignItems:'center',cursor:'pointer'}} onClick={()=> window.location="/help"}>
          <QuizIcon className='sidebar-item-icon'/>
            FAQs
            </p>
          <p style={{display:'flex',alignItems:'center',cursor:'pointer'}} onClick={()=> window.location="/contact"}>
          <PermPhoneMsgIcon className='sidebar-item-icon'/>
           Contact Us
            </p>
        </div>)
        }
      <div onClick={handlelogout} className="sidebar-item s-item6">
      <LogoutIcon className='sidebar-item-icon'/>
        <span>Logout</span>
      </div>
    </div>
  )
}
