import React , {useState} from 'react'
import './Navbar.css'

export default function Navbar() {

  const [sidebarOpen, setSidebarOpen] = useState(false);
// eslint-disable-next-line
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };
  return (
    <nav >
        <div className="navbar">
      <div className="navbrand">
        <img onClick={()=> window.location="/platform"} src="salarylogoblack.png" alt="" className="navimg" />
      </div>

      {/* <div className="menu-icon" onClick={toggleSidebar}>
          <img
            src={sidebarOpen ? "https://cdn-icons-png.flaticon.com/128/2732/2732657.png" : "https://cdn-icons-png.flaticon.com/128/6015/6015685.png" }
            alt="Menu"
            className="menu-img"
          />
        </div> */}

        {/* <div className={`sidebar ${sidebarOpen ? 'open' : ''}`}>
      <div className="navcontents">
        <button onClick={()=> window.location="https://salarytrader.com/"} className="nav-btn">
            Home
        </button>
        <button onClick={()=> window.location="https://salarytrader.com/index.php/about/"} className="nav-btn">
            About
        </button>
        <button onClick={()=> window.location="https://salarytrader.com/index.php/contact/"} className="nav-btn">
            Contact
        </button>
        <button onClick={()=> window.location="https://test.projaat.com/"} className="nav-login-btn">
            Login
        </button>
      </div>
      </div> */}
      </div>
      <div style={{height:'1px',width:'100%',backgroundColor:'rgb(80, 80, 80,0.5)'}}></div>
    </nav>
  )
}
