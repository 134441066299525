import React from 'react'
import './Tutorial.css'
import tutorialList from './tutorialList.js'

export default function Tutorial() {
  return (
    <div style={{backgroundColor:'#121212',height:'100vh',overflowY:'auto'}}>
      <div className="tutorial">
        <button onClick={()=> window.location="/"} style={{marginLeft:'20px',marginTop:'20px',backgroundColor:'transparent',color:'white',border:'2px #007aff solid',borderRadius:'10px',padding:'5px',cursor:'pointer'}}>Go to Dashboard</button>
        <h1 style={{fontFamily:'All',color:'White',marginLeft:'20px',fontSize:'32px'}}>Tutorial</h1>
        <div style={{marginLeft:'20px'}}>
        <span style={{fontFamily:'All',color:'White',fontSize:'14px'}}>
        How to Start Trading on SalaryTrade: A Complete Tutorial
        Welcome to SalaryTrade! Here, we provide an opportunity for you to earn by predicting market trends. Follow this tutorial to get started and understand the rules.
        </span>

        </div>

        {Array.from(Array(5),(e,i)=>{
            return <div className="tut-div">
                <span className="tut-head">
                    {tutorialList[i].title}
                </span>
                <span className="tut-con">
                {tutorialList[i].contents}

                </span>
                <img src={tutorialList[i].image} alt="" />
            </div>
        })}
        <div style={{marginLeft:'20px',marginBottom:'10%',display:'flex',justifyContent:'center',marginTop:'40px'}}>
        <span style={{fontFamily:'All',color:'White',fontSize:'14px'}}>
        Contact Us
For more questions, contact us at info@salarytrader.com or create a ticket from the help section. We will answer you within 24 to 48 hours.
        </span>

        </div>

      </div>
    </div>
  )
}
