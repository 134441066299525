import React, { useEffect } from 'react'
import './Topbar.css'
import axios from 'axios'
import * as moment from 'moment'


export default function Topbar({handleClick,isClick,visible,handleSidebar,isSidebar,globaluuid, apilink}) {
    // var apilink = 'https://test.projaat.com/api';
  // var apilink = 'http://localhost:8080/api';
  // var apilink = 'http://192.168.0.105:8080/api';
const [myLevel,setMylevel] = React.useState(0);
const [wallet,setWallet] = React.useState(0);
const EraningList = [0,1,2,2,2,5,5,5,5,5,10,15,20,30,40,50];
const formatDate = (date) => {
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

  useEffect(()=>{
    axios.post(apilink+'/updatelastime', {globaluuid})
            .then(res => {
              console.log('last updated');
            })
            .catch(err => console.log(err));

    axios.post(apilink+'/level', {globaluuid})
            .then(res => {
              const globalData = JSON.parse(res.data.predictions);
              const parseData = (globalData) => {
                const sortedDates = Object.keys(globalData).sort((a, b) => new Date(b.split("/").reverse().join("-")) - new Date(a.split("/").reverse().join("-")));
                
              return sortedDates.map(date => ({
                date,
                results: Object.values(globalData[date]).map(entry => entry.result)
              }));
              };

              setMylevel(res.data.status);
              setWallet(res.data.wallet);
              const timeStored = new Date(res.data.resettime);
              const currentTime = new Date();
              const yearStored = timeStored.getFullYear();
              const yearCurrent = currentTime.getFullYear();
              const dateStored = timeStored.getDate();
              const dateCurrent = currentTime.getDate();
              const monthStored = timeStored.getMonth();
              const monthCurrent = currentTime.getMonth();
              var tempwallet = res.data.wallet;


            const functionLevel = () => {

              // <----------------------------------------------------------------------------------->
              // const checkIncentives = (parsedData) => {
              //   let incentivesCount = 0;
              //   let weekTrueResultsCount = 0;
              //   let currentWeek = null;
    
              //   parsedData.forEach(day => {
              //     const date = new Date(day.date.split('/').reverse().join('-'));
              //     const weekStart = new Date(date.setDate(date.getDate() - date.getDay() + 1)); // Monday
              //     const weekEnd = new Date(date.setDate(date.getDate() - date.getDay() + 5)); // Friday
    
              //     if (!currentWeek || weekStart > currentWeek.end) {
              //       if (weekTrueResultsCount >= 12 * 5) {
              //         incentivesCount++;
              //       }
              //       currentWeek = { start: weekStart, end: weekEnd };
              //       weekTrueResultsCount = 0;
              //     }
    
              //     const trueResults = day.results.filter(result => result === true).length;
              //     weekTrueResultsCount += trueResults;
              //   });
    
              //   if (weekTrueResultsCount >= 12 * 5) {
              //     incentivesCount++;
              //   }
    
              //   return incentivesCount;
              // };

              // <----------------------------------------------------------------------------------->

            // }
            //   if(res.data.status === 'Level Zero'){
              const checkResults = (parsedData) => {
                const today = new Date();
                const startOfWeek = new Date(today.setDate(today.getDate() - today.getDay() + 1)); // Monday
                const endOfWeek = new Date(today.setDate(today.getDate() - today.getDay() + 5)); // Friday
                let daysWith12TrueResults = 0;
    
                parsedData.forEach(day => {
                  const date = new Date(day.date.split('/').reverse().join('-'));
                  if (date >= startOfWeek && date <= endOfWeek) {
                    const trueResults = day.results.filter(result => result === true).length;
                    if (trueResults >= 12) {
                      daysWith12TrueResults++;
                    }
                  }
                });
    
                return daysWith12TrueResults >= 3;
              };
    
              const parsedData = parseData(globalData);
              const hasThreeDaysWith12TrueResults = checkResults(parsedData);
              // const totalIncentives = checkIncentives(parsedData); ---------------------->

              console.log('res'+ hasThreeDaysWith12TrueResults)



    if (hasThreeDaysWith12TrueResults) {
      console.log('yes');
      if(myLevel < 15){
      // const addEarn = Number(tempwallet) + (EraningList[myLevel]*totalIncentives) ; ---------------------->
      const addEarn = Number(tempwallet);
      // const addEarn = Number(tempwallet);
      let updatedstatus = myLevel;
      updatedstatus++
    axios.post(apilink+'/updatelevel', {updatedstatus,currentTime,addEarn,globaluuid})
            .then(res => {
              console.log(res.data);
              setMylevel(updatedstatus);
              setWallet(addEarn);

            })
            .catch(err => console.log(err));
    }
    } else {
      console.log('no');
      if(myLevel > 0 ){
      let updatedstatus = myLevel;
      const addEarn = Number(tempwallet) - EraningList[myLevel];
      updatedstatus--
      axios.post(apilink+'/updatelevel', {updatedstatus,currentTime,addEarn,globaluuid})
      .then(res => {
        console.log(res.data);
        setMylevel(updatedstatus);
        setWallet(addEarn);
        
      })
      .catch(err => console.log(err));
    }
    }
        
  } //function ends here

  if(currentTime.getDay() === 5 && currentTime.getHours() > 20){
    functionLevel();
  } else {
    if(yearStored !== 2000 && (currentTime.getDay() === 5?(currentTime.getHours() > 20):(true))){
    if(dateCurrent > dateStored){  
      console.log('date:'+dateCurrent)

      functionLevel();

    } else if(monthCurrent > monthStored){

      functionLevel();

    } else if(yearCurrent > yearStored){

      functionLevel();
      
    }
  }
  }
                      
            })
              .catch(err => console.log(err));
  }

  )

  useEffect(()=>{
    const currentTime = new Date();
      axios.post(apilink+'/level', {globaluuid})
      .then(res => {
        const globalData = JSON.parse(res.data.predictions);
        const currentDate = new Date();
        const lastDate = new Date(res.data.earningtime);
        const sortedData = Object.keys(globalData).sort((a, b) => new Date(b.split("/").reverse().join("-")) - new Date(a.split("/").reverse().join("-")));
        const newDate = new Date(moment(sortedData[0] ,"dd/mm/yyyy"));
        let tempwallet = res.data.wallet;
        

        const functionEarnings = () =>{
        const addEarn = Number(tempwallet) + EraningList[myLevel];

        if(newDate > lastDate){
          if(!!globalData[sortedData[0]]){
            const indata = Object.values(globalData[sortedData[0]]).map(entry => entry.result)
            const filterdata = indata.filter(result => result === true)
            if(filterdata.length > 11){
              axios.post(apilink+'/updateearnings', {addEarn,currentTime,globaluuid})
              .then(res => {
              console.log(res.data);
              setWallet(addEarn);
              })
              .catch(err => console.log(err));
            }
          }
        }
      } //function end here

        
        if(formatDate(newDate) === formatDate(currentDate)){
          if(currentTime.getHours() > 20){
            functionEarnings();
          }
        } else {
          functionEarnings();
        }

        
      })
      .catch(err => console.log(err));
  })
  return (
    <div>
{/* <button onClick={handleClick} style={{borderRadius:'10px',border:'0px',display:'flex'}}>
        {isClick?'+':'x'}
      </button> */}
    
    <div style={visible === 'true'?{display:'flex'}:{display:'none'}} className='topbar'>
      <div className='in-topbar'>
        {/* {!isSidebar && <div className="sidespace"></div>} */}
        {isSidebar && 
        <div className="topbar-header">
            {/* <img src="stlogo.png" alt="" /> */}
            <img onClick={()=> window.location="/"} src="SalaryNewLogo.png" alt="" />
            {/* <h2>Salary Trader</h2> */}
        </div>
        
        }
        
      {/* <img onClick={handleSidebar} className='sidebar-btn' src={!isSidebar? "close.png":"menu.png"} alt="" />    */}

      </div>
      {/* <button onClick={handleClick} style={{borderRadius:'10px',border:'0px',display:'flex'}}>
        {isClick?'Open':'Close'}
      </button> */}
      <div className='status-div'>
      <h2 className='wallet-main-txt'>
      {/* <img style={{width:'40px',height:'40px',marginRight:'-5px'}} src="walletmain.png" alt="" /> */}
        USD {wallet}
        </h2>
      {/* <button className='status-btn'>
        Level : {myLevel}
      </button> */}
      </div>
      {/* <div className='status-div'>
      <span className='status-txt'>Status</span>
      <button className='status-btn'>
        Test
      </button>
      </div> */}
      <div className='inn-topbar'>
      {/* <h2 className='wallet-main-txt'>1009</h2> */}
      {/* <img className='account-btn' src="user(11).png" alt="" /> */}
      <img onClick={handleSidebar} className='sidebar-btn' src={!isSidebar? "close.png":"menunew.png"} alt="" />   

      </div>
    </div>
    </div>
  )
}
