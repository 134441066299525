import React, { useState } from 'react';
import './Contact.css'
import { toast } from 'react-custom-alert';


export default function Contact({apilink}) {
    
        const [formData, setFormData] = useState({
          email: '',
          username: '',
          subject: 'Account Related',
          query: ''
        });
      
        const handleChange = (e) => {
          const { name, value } = e.target;
          setFormData({
            ...formData,
            [name]: value
          });
        };
      
        const handleSubmit = async (e) => {
          e.preventDefault();
          // Handle form submission
          console.log('Form data:', formData);
          try {
            const response = await fetch(apilink+'/send', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(formData)
            });
      
            if (response.ok) {
              toast.success('Your query has been sent successfully!');
            } else {
              toast.warning('There was an error sending your query. Please try again later.');
            }
          } catch (error) {
            console.error('Error:', error);
            toast.warning('There was an error sending your query. Please try again later.');
          }
        };
  return (
    <div style={{backgroundColor:'#121212',height:'100vh',overflowY:'auto'}}>
        {/* <div style={{width:'100%',height:'25%',backgroundColor:'#1e2228',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
            <img src="https://cdn-icons-png.flaticon.com/128/8943/8943377.png" style={{width:'75px',height:'75px'}} alt="" />
            <span style={{color:'white',fontFamily:'Bold',fontSize:'20px',marginTop:'5px'}}>Salary Trader</span>
            <span style={{color:'white',fontFamily:'All',fontSize:'14px',marginTop:'5px'}}>Support</span>
        </div> */}
      <div className="contact-us-container">
      <h2>Contact Us</h2>
        <div className="topiconcontact">
        <img style={{width:'25px',height:'25px'}} src="https://cdn-icons-png.flaticon.com/128/3237/3237472.png" alt="" />

      <h4>Any Question? Just write us a message!</h4>
        </div>
      <form onSubmit={handleSubmit}>
        <label>
          Email
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Username
          <input
            type="text"
            name="username"
            value={formData.username}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Subject
          <select
            name="subject"
            value={formData.subject}
            onChange={handleChange}
          >
            <option value="Account Related">Account Related</option>
            <option value="Kyc Related">Kyc Related</option>
            <option value="Withdrawal Related">Withdrawal Related</option>
            <option value="Earnings Related">Earnings Related</option>
            <option value="Other Queries">Other Queries</option>
          </select>
        </label>
        <label>
          Query
          <textarea
            name="query"
            value={formData.query}
            onChange={handleChange}
            style={{height:'15vh'}}
            required
          />
        </label>
        <button type="submit">Submit</button>
      </form>
    </div>
    </div>
  )
}
