import React, {useEffect,useState} from 'react'
import './Withdrawal.css'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// eslint-disable-next-line
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import axios from 'axios';
import { toast } from 'react-custom-alert';


export default function Withdrawal({globaluuid,apilink}) {
  const [addPayment,setaddPayment] = React.useState(false);
  const [paymentMode,setpaymentMode] = React.useState('Bank');
  const [mycountry,setmycountry] = React.useState('');
  const [selectedCrypto,setselectedCrypto] = React.useState('btc');
  const [selectedCryptotype,setselectedCryptotype] = React.useState('bep20');
  const [bankName,setbankName] = React.useState('');
  const [bankNo,setbankNo] = React.useState('');
  const [ifsc,setifsc] = React.useState('');
  const [phone,setphone] = React.useState('');
  const [email,setemail] = React.useState('');
  const [upi,setupi] = React.useState('');
  const [username,setusername] = React.useState('');
  const [usercountry,setusercountry] = React.useState('');
  const [usermobile,setusermobile] = React.useState('');
  const [usercity,setusercity] = React.useState('');
  const [useremail,setuseremail] = React.useState('');
  const [wAddress,setwAddress] = React.useState('');
  const [Amount,setAmount] = React.useState(0);
  const [withData,setWithData] = React.useState({});

  const handleModeChange = (event) => {
    setpaymentMode(event.target.value);
  };
  const openbankinfo = () =>{
    setaddPayment(!addPayment);
  };
  const selectCrypto = (e) =>{
    document.getElementById('btc').style.backgroundColor = "#626262";
    document.getElementById('usdt').style.backgroundColor = "#626262";
    document.getElementById('bnb').style.backgroundColor = "#626262";
    e.target.style.backgroundColor = '#0097fe';
    setselectedCrypto(e.target.id);
  };
  const selectCryptotype = (e) =>{
    document.getElementById('bep20').style.backgroundColor = "#626262";
    document.getElementById('trc20').style.backgroundColor = "#626262";
    e.target.style.backgroundColor = '#0097fe';
    setselectedCryptotype(e.target.id);
  };
  const withback = () =>{
    setaddPayment(!addPayment);
  };
  const submitDetails = () =>{
    var withdrawAvailble = false;
    var payDetailslist = [];
    if(Number(wallet)>=Number(Amount) && Number(Amount) >= 100){
      console.log(Number(wallet)>=Number(Amount),wallet,Amount)
      if(paymentMode === 'Bank' && !!bankName && !!bankNo && !!ifsc && !!phone){
        payDetailslist = [
          bankName,
          bankNo,
          ifsc,
          phone,
        ]
        withdrawAvailble = true;
      }else if(paymentMode === 'UPI' && !!upi && upi.includes('@')){
        setaddPayment(!addPayment);
        payDetailslist = [
          upi,
        ]
        withdrawAvailble = true;
      }else if(paymentMode === 'Paypal' && !!email && email.includes('@')){
        setaddPayment(!addPayment);
        payDetailslist = [
          email,
        ]
        withdrawAvailble = true;
      }else if(paymentMode === 'Crypto' && !!wAddress ){
        setaddPayment(!addPayment);
        payDetailslist = [
          wAddress,
          selectedCrypto,
          selectedCrypto === 'usdt' && selectedCryptotype,
        ]
        withdrawAvailble = true;
      }else{
        withdrawAvailble = false;
        toast.warning('Please enter correct details')
      }
      if(withdrawAvailble){

        const payDetails = JSON.stringify(payDetailslist);
        axios.post(apilink+'/withdraw', {username,usercountry,usermobile,globaluuid,usercity,useremail,Amount,paymentMode,payDetails})
                .then(res => {
        const tempwallet = wallet - Amount;
        setWallet(tempwallet);
        axios.post(apilink+'/updatewallet', {tempwallet,globaluuid})
              .then(res => {
        setaddPayment(!addPayment);
        toast.success('Withdrawal Successfully Submitted')
                
              })
                .catch(err => console.log(err));  
                  
                })
                  .catch(err => console.log(err));  
      }
    } else {
      toast.warning(Number(Amount) < 100 ?'Minimum amount must be more than $100':'Insufficient Balance')
    }
  };

  const [wallet,setWallet] = useState(0);
  useEffect(() => {
    axios.post(apilink+'/getwithdraw', {globaluuid})
              .then(res => {
                setWithData(res.data);
               
              })
                .catch(err => console.log(err));   

    axios.post(apilink+'/level', {globaluuid})
            .then(res => {
              setWallet(res.data.wallet);
              setuseremail(res.data.email);
              setusercity(res.data.city);
              setusercountry(res.data.country);
              setusermobile(res.data.mobile);
              setusername(res.data.first + ' ' + res.data.last);
            })
              .catch(err => console.log(err));
    axios.post('https://ipapi.co/json/')
            .then(res => {
              setmycountry(res.data.country_name)
            })
              .catch(err => console.log(err));

  }, [globaluuid,apilink,wallet]);
  return (
    <div style={{backgroundColor:'#121212',height:'auto'}}>
      
      <div className="topbar-withdraw">
        <ArrowBackIcon onClick={addPayment?(withback):(()=> window.location="/")} className='withdrawal-item-icon'/>
        <span className='title-withdrawal'>Withdrawal</span>
        <div className="wspace"></div>
        {/* <HelpOutlineIcon className='withdrawal-help-item-icon'/> */}
      </div>
      <div style={addPayment?{display:'none'}:{display:'flex'}} className="with-page">
      <div className="with-balance">
        {/* <img src="https://cdn-icons-png.flaticon.com/128/11193/11193904.png" alt="" className="with-bal-img" /> */}
        <div className="in-with-bal">
          <span className="with-txt1-bal">Available Balance</span>
          <span className="with-txt2-bal">USD {wallet}</span>
        </div>
          {/* <ArrowForwardIosIcon className='withdrawal-help-item-icon'/> */}
      </div>
      <div onClick={openbankinfo} className="add-with">
        <h4>Withdraw</h4>
        <ArrowForwardIosIcon className='withdrawal-help-item-icon'/>
      </div>
      <span className='history-txt'>History</span>
      <div style={{height:'100vh',width:'90%'}}>
      {withData.length>= 1?(
        withData.map((item)=>(
          <div key={item} className="with-history">
        <div className="with-history-div">
          <div className="in-with-history">
          <h3>${item.amount} Withdrawal</h3>
          <span>{item.mode}</span>
          </div>
          <img style={{width:'30px',height:'30px'}} src={item.status === 'success'?"https://cdn-icons-png.flaticon.com/128/190/190411.png":item.status === 'pending'?"https://cdn-icons-png.flaticon.com/128/6897/6897039.png":"https://cdn-icons-png.flaticon.com/128/10308/10308693.png"} alt="" />
        </div>
        <div className="with-history-line"></div>
      </div>
        ))
      ):(
        <div style={{height:'100vh'}}></div>
      )}
      </div>
      
      </div>
      <div style={addPayment?{display:'flex'}:{display:'none'}} className="with-bank">
        <h4>Add Details</h4>
        <div className="bank-txtbox">
          <span className='bank-txt'>Amount</span>
          <input onChange={e => setAmount(e.target.value)} id='t8' className='bank-input' type="number" />
        </div>
        <div className="bank-txtbox">
          <span className='bank-txt'>Select Payment Mode</span>
          <select value={paymentMode} onChange={handleModeChange} className='bank-input' name="" id="">
            <option value="Bank">Bank Transfer</option>
            {mycountry === 'India' && <option value="UPI">UPI</option>}
            <option value="Paypal">Paypal</option>
            {/* <option value="Neteller">Neteller</option> */}
            {/* <option value="Skrill">Skrill</option> */}
            <option value="Crypto">Crypto</option>
          </select>
        </div>
        {paymentMode === 'Bank'&& <div className='with-bank' style={{width:'100%'}}>
          <div className="bank-txtbox">
          <span className='bank-txt'>Bank Name</span>
          <input onChange={e => setbankName(e.target.value)} id='t1' className='bank-input' type="text" />
        </div>
        <div className="bank-txtbox">
          <span className='bank-txt'>Bank Account Number</span>
          <input onChange={e => setbankNo(e.target.value)} id='t2' className='bank-input' type="number" />
        </div>
        <div className="bank-txtbox">
          <span className='bank-txt'>IFSC Code</span>
          <input onChange={e => setifsc(e.target.value)} id='t3' className='bank-input' type="text" />
        </div>
        <div className="bank-txtbox">
          <span className='bank-txt'>Phone</span>
          <input onChange={e => setphone(e.target.value)} id='t4' className='bank-input' type="number" />
        </div>
        </div>}
        {paymentMode === 'UPI'&& 
          <div className="bank-txtbox">
          <span className='bank-txt'>UPI ID</span>
          <input onChange={e => setupi(e.target.value)} id='t5' className='bank-input' type="text" />
        </div>}
        {paymentMode === 'Paypal'&& 
          <div className="bank-txtbox">
          <span className='bank-txt'>Paypal Email</span>
          <input onChange={e => setemail(e.target.value)} id='t6' className='bank-input' type="email" />
        </div>}
        {paymentMode === 'Crypto'&& 
        <div className='with-bank' style={{width:'100%'}}>
          <div className="bank-txtbox">
          <span className='bank-txt'>Wallet Address</span>
          <input onChange={e => setwAddress(e.target.value)} id='t7' className='bank-input' type="text" />
        </div>
        <span style={{margin:'5px',color:'white',fontFamily:'Box',marginRight:'5px',pointerEvents:'none',width:'90%'}}>Select Coin</span>
        <div style={{display:'flex',width:'90%'}}>
            <div id='btc' onClick={selectCrypto} style={{backgroundColor:'#0097fe',width:'16%',borderRadius:'10px',padding:'10px',display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer'}}>
              <img style={{width:'25px',height:'25px',pointerEvents:'none'}} src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/46/Bitcoin.svg/2048px-Bitcoin.svg.png" alt="" />
              <span style={{marginLeft:'5px',color:'white',fontFamily:'Box',marginRight:'5px',pointerEvents:'none'}}>BTC</span>
            </div>
            <div id='usdt' onClick={selectCrypto} style={{backgroundColor:'#626262',width:'18%',borderRadius:'10px',padding:'10px',display:'flex',justifyContent:'center',alignItems:'center',marginLeft:'10px',cursor:'pointer'}}>
              <img style={{width:'25px',height:'25px',pointerEvents:'none'}} src="https://cryptologos.cc/logos/tether-usdt-logo.png" alt="" />
              <span style={{marginLeft:'5px',color:'white',fontFamily:'Box',marginRight:'5px',pointerEvents:'none'}}>USDT</span>
            </div>
            <div id='bnb' onClick={selectCrypto} style={{backgroundColor:'#626262',width:'18%',borderRadius:'10px',padding:'10px',display:'flex',justifyContent:'center',alignItems:'center',marginLeft:'10px',cursor:'pointer'}}>
              <img style={{width:'25px',height:'25px',pointerEvents:'none'}} src="https://seeklogo.com/images/B/binance-coin-bnb-logo-CD94CC6D31-seeklogo.com.png" alt="" />
              <span style={{marginLeft:'5px',color:'white',fontFamily:'Box',marginRight:'5px',pointerEvents:'none'}}>BNB</span>
            </div>
          </div>
          {selectedCrypto === 'usdt' && 
          <div className='with-bank' style={{width:'100%'}}>
            <span style={{margin:'5px',marginTop:'20px',color:'white',fontFamily:'Box',marginRight:'5px',pointerEvents:'none',width:'90%'}}>Select Type</span>
        <div style={{display:'flex',width:'90%'}}>
            <div id='bep20' onClick={selectCryptotype} style={{backgroundColor:'#0097fe',width:'18%',borderRadius:'10px',padding:'10px',display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer'}}>
              <img style={{width:'25px',height:'25px',pointerEvents:'none'}} src="https://cryptologos.cc/logos/tether-usdt-logo.png" alt="" />
              <span style={{marginLeft:'5px',color:'white',fontFamily:'Box',marginRight:'5px',pointerEvents:'none'}}>BEP20</span>
            </div>
            <div id='trc20' onClick={selectCryptotype} style={{backgroundColor:'#626262',width:'18%',borderRadius:'10px',padding:'10px',display:'flex',justifyContent:'center',alignItems:'center',marginLeft:'10px',cursor:'pointer'}}>
              <img style={{width:'25px',height:'25px',pointerEvents:'none'}} src="https://cryptologos.cc/logos/tether-usdt-logo.png" alt="" />
              <span style={{marginLeft:'5px',color:'white',fontFamily:'Box',marginRight:'5px',pointerEvents:'none'}}>TRC20</span>
            </div>
          </div>
          </div>
          }
        
        </div>
        
        }
        
        <div onClick={submitDetails} className="add-bank-btn-with">
        <span>Submit</span>
        <ArrowForwardIosIcon className='withdrawal-help-item-icon'/>
      </div>
      </div>
    </div>
  )
}
