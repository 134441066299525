import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Leaderboard.css'
import iso31661 from 'iso-3166-1';
import countriesList from './countriesList.js';

export default function Leaderboard({apilink,globaluuid}) {
    const [users, setUsers] = useState([]);
    const [countries, setCountries] = useState([]);
    const [token, setToken] = useState('');

    useEffect(() => {
      const login = async (getusername, getpassword) => {
          try {
              const response = await axios.post(`${apilink}/auth`, { username: getusername, password: getpassword });
              setToken(response.data.token);
          } catch (error) {
              console.error('Login Error:', error);
          }
      };

      const fetchCredentials = async () => {
          try {
              const res = await axios.post(`${apilink}/level`, { globaluuid });
              console.log('logg'+ res.data.username);
              const getusername = res.data.username;
              const getpassword = res.data.password;
              login(getusername, getpassword);
          } catch (error) {
              console.error('Fetch Credentials Error:', error);
          }
      };

      fetchCredentials();
  }, [apilink, globaluuid]);
   
    useEffect(() => {
        const fetchData = async () => {
          if (!token) return;
          try {
            const result = await axios.get(apilink + '/all', {
              headers: { Authorization: token }
            });
            setUsers(result.data);
          } catch (err) {
            console.error(err);
          }
        };
        fetchData();

        const fetchFlags = async () => {
          const countriesWithFlags = countriesList.map((country) => {
            const countryCode = iso31661.whereCountry(country)?.alpha2?.toLowerCase();
            const flagUrl = `https://flagcdn.com/w320/${countryCode}.png`;
            return { name: country, flagUrl };
          });
          setCountries(countriesWithFlags);
        };

        fetchFlags();
      }, [apilink,token]);

      const processPredictions = (predictions) => {
        let trueCount = 0;
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1; // JavaScript months are 0-11
        const currentYear = currentDate.getFullYear();
    
        Object.entries(predictions).forEach(([date, times]) => {
          // eslint-disable-next-line
          const [day, month, year] = date.split('/').map(Number);
          if (month === currentMonth && year === currentYear) {
            Object.values(times).forEach(time => {
              if (time.result === true) {
                trueCount++;
              }
            });
          }
        });
    
        return trueCount;
      };
    
      const leaderboard = users.map(user => ({
        ...user,
        trueResults: processPredictions(JSON.parse(user.predictions))
      })).sort((a, b) => b.trueResults - a.trueResults).slice(0, 10);
  return (
    <div>
      <div className='leaderboard'>
      <h1>Monthly Leaderboard</h1>
      {leaderboard.map((user, index)=>(
        <div className="leaderboard-list">
          <h2 className='leader-rank'>{index+1}</h2>
          <div className='rank-title'>
            <span>{user.username+' '}
          <img style={{width:'18px',height:'10px',marginLeft:'5px'}} src={countries[countriesList.indexOf(user.country)].flagUrl} alt="" />

            </span>
            {/* <h5>@{user.username}</h5> */}
          </div>
          {/* <h3>{user.trueResults}</h3> */}
        </div>
      ))}
    </div>
    </div>
  )
}
