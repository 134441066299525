import React from 'react'
import './Login.css'
import { useState,useEffect } from 'react'
import axios from 'axios'
import countriesList from './countriesList.js';
import { CircularProgress } from "@mui/material";
// import { toast } from 'react-custom-alert';


export default function Login({setglobalLogin,visible, apilink,setglobaluuid}) {
  const [account , setaccount] = useState(false); //to check login or signup
  const [already , setAlready] = useState(false); //to check already have an account , also used for show error
  const [next , setNext] = useState(false); //after login or signup successfull
  const [error , setError] = useState(false); //error msg
  const [email , setEmail] = useState('');
  const [fName , setFName] = useState('');
  const [lName , setLName] = useState('');
  const [mobile , setMobile] = useState('');
  const [city , setCity] = useState('');
  const [refer , setRefer] = useState('-');
  const [password , setPassword] = useState('');
  const [confPassword , setConfPassword] = useState('');
  const [fullName , setFullName] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const isLogin = localStorage.getItem('userId');
  const [otp, setOtp] = useState('');
  const [code, setCode] = useState('+91');
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isOtpDone, setIsOtpDone] = useState(false);
  const [isOtpFail, setIsOtpFail] = useState(false);

  // var apilink = 'https://test.projaat.com/api';
  // var apilink = 'http://localhost:8080/api';
  // var apilink = 'http://192.168.0.105:8080/api';

  // localStorage.removeItem('userId');

  const WelcomeMail = async () => {
    // e.preventDefault();
    // Handle form submission
    console.log('Form data:', email,fullName);
    try {
      const response = await fetch(apilink+'/send-welcome-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: email,
          username: fullName
        })
      });

      if (response.ok) {
        // toast.success('Signup Successfull');
        
      } else {
        // alert('There was an error sending your query. Please try again later.');
      }
    } catch (error) {
      console.error('Error:', error);
      // alert('There was an error sending your query. Please try again later.');
    }
  };
 console.log(next);
  useEffect(()=>{

    if (isLogin) {
      
    }
  }
  )



  function isValidEmail(email) {
    // Regular expression pattern for validating email addresses
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  }
  async function ipTrack(ipcheck) {
    try {
      const res = await axios.post('https://ipapi.co/json/');
      const ipnow = ipcheck === '' ? res.data.ip : ipcheck === res.data.ip;
      console.log('myip ' + ipnow);
      return ipnow;
    } catch (err) {
      console.log(err);
      return null; // Or handle the error appropriately
    }
  }
  const handleclick = ()=>{
    if (account) {
      setaccount(false);
      document.querySelector(".login-grad-out").classList.remove("move-right");
    document.querySelector(".card-container").classList.remove("move-left");
    document.querySelector(".login-grad").classList.remove("adjustlogin");

    } else {
      setaccount(true);
      document.querySelector(".login-grad-out").classList.add("move-right");
    document.querySelector(".card-container").classList.add("move-left");
    document.querySelector(".login-grad").classList.add("adjustlogin");

    }
  }
  const handlelogout = ()=>{
    localStorage.removeItem('userId');
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    setaccount(false);
    setglobalLogin();

  }, 3000);
  }
// eslint-disable-next-line
  const phoneAuth = () => {
    window.OTPlessSignin.initiate({
      channel: "PHONE",
      phone: mobile,
      countryCode: code, // Adjust country code as needed
    }).then(()=>{
      setIsOtpSent(true);
    });
  };


  const verifyOTP = () => {
    window.OTPlessSignin.verify({
      channel: "PHONE",
      phone: mobile,
      otp: otp,
      countryCode: code,
    }).then((response)=>{
      console.log('Response:', response);
      setIsOtpDone(response.statusCode === 200);
      setIsOtpFail(response.statusCode !== 200);
      console.log('otpcode'+ response.statusCode)
      if(response.statusCode !== 200){
        setAlready(true);
        setError('OTP Verification Failed');
      } else{
        setAlready(false);
      }
    });
    };


    


  function handleSubmit (event){
    setIsLoading(true);
    // const alphanumericRegex = /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]{8,20}$/;
    const alphanumericRegex = /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9!@#$%^&*()_+~\-=`{}[\]:";'<>?,./]{8,20}$/;


    // if (!email || !password || (!account && (!fullName || !selectedCountry || !confPassword || (password !== confPassword) || !isOtpDone || !alphanumericRegex.test(password) || fullName.length < 3))) {
    if (!email || !password || (!account && (!fullName || !selectedCountry || !confPassword || (password !== confPassword) || !alphanumericRegex.test(password) || fullName.length < 3))) {
      setAlready(true);
      setError(!!alphanumericRegex.test(password)?'Please fill out all fields.':'Password must be alphanumeric and minimum 8 characters long');
      setIsLoading(false);
      if(fullName.length < 3){
        setError('Username must be more than 3 letters');
      }
      if (!account &&(password !== confPassword)) {
        setError('Password does not match');
      };
      if (!isValidEmail(email)){
        setError('Please enter valid email');
      }
      // if(!isOtpDone){
      //   setError('Please verify phone number');
      // }
    } else {
      if (account){
        event.preventDefault();
      axios.post(apilink+'/check', {email})
      .then(res => {
        if (res.data) {
          event.preventDefault();
      axios.post(apilink+'/login', {email, password})
      .then(res => {
        if (res.data) {
          if(res.data[res])
          setNext(true);
          setAlready(false);
          console.log('Login Successfull');
          document.body.classList.remove('reports-scroll');
          document.documentElement.classList.remove('reports-scroll');
          // var ipx = '';
          event.preventDefault();
          axios.post(apilink+'/uuid', {email})
          .then(res => {   
            // ipx = res.data[0].myip
            // if(!ipTrack(ipx)){
            if(true){
              localStorage.setItem('uuid', res.data[0].ID);
              localStorage.setItem('userId', email);
              setglobalLogin(localStorage.getItem('userId'));
              setglobaluuid(localStorage.getItem('uuid'));
              setIsLoading(true);
              setTimeout(() => {
                setIsLoading(false);
            }, 3000);
            } else {
              setAlready(true);
            setError('You cant use more accounts');
            setNext(false);
            console.log('Login accounts Failed');
            setIsLoading(false);
            }
          } )
          .catch(err => console.log(err));
          
        } else {
          setAlready(true);
          setError('Your Password Wrong');
          setNext(false);
          console.log('Login Failed');
          setIsLoading(false);
        }
      })
        .catch(err => console.log(err));
        }else{
          setAlready(true);
          setError('You dont have an account, please Signup');
          setIsLoading(false);
        }})
        .catch(err => console.log(err));
        
      } else {
      event.preventDefault();
      axios.post(apilink+'/check', {email})
      .then(res => {
        if (res.data) {
          setAlready(true);
          setError('You already have an account');
          setIsLoading(false);
        } else {
          ipTrack('')
          .then(ipnow => {
           console.log('Returned IP:', ipnow);
           console.log('workingIpx'+ ipnow);
           event.preventDefault();
           axios.post(apilink+'/checkip', {ipnow})
           .then(res => {   
             if(res.data){
              event.preventDefault();
              axios.post(apilink+'/checkusername', {fullName})
              .then(res => {   
                if(res.data){
                  axios.post(apilink+'/checkmobile', {mobile})
                  .then(res => {   
                  if(res.data){
                    setAlready(false);
                    const myip = ipnow;
                    console.log('workingIp'+ myip);
                    event.preventDefault();
                    const referr = !!refer?(refer.toLowerCase()):('-');
                    axios.post(apilink+'/signup', {email, password, fullName, selectedCountry, fName, lName, mobile, city, myip, referr})
                    // axios.post('https://test.projaat.com/api/signup', {email, password, fullName, selectedCountry})
                    .then(res => {if (res.status === 200) {
                      setNext(true);
                      setAlready(false);
                      console.log('Signup Successfull');
                      document.body.classList.remove('reports-scroll');
                document.documentElement.classList.remove('reports-scroll');
                      event.preventDefault();
                    axios.post(apilink+'/uuid', {email})
                    .then(res => {   
                      localStorage.setItem('uuid', res.data[0].ID);
                      setglobaluuid(localStorage.getItem('uuid'));

                      
                    } )
                    .catch(err => console.log(err));
                    
                      localStorage.setItem('userId', email);
                      WelcomeMail();
                    setglobalLogin(localStorage.getItem('userId'));
                      setIsLoading(true);
                      setTimeout(() => {
                        setIsLoading(false);
                        window.location.href = '/tutorial';
                    }, 3000);
                    } else {
                      console.log('Signup Failed');
                      setIsLoading(false);
                      setNext(false);
                      setAlready(true);
                      setError('Signup Failed');
          
                    }
                   
                    })
                    .catch(err => console.log(err));
                } else {
                  console.log('mobile already exist');
                setIsLoading(false);
                setNext(false);
                setAlready(true);
                setError('Mobile Number already exist');
                }
          
                })
                .catch(err => console.log(err));
                  
                }else {
                console.log('Username already exist');
                setIsLoading(false);
                setNext(false);
                setAlready(true);
                setError('Username already exist');
                
                }
               })
               .catch(err => console.log(err));
               
               
             }else{
               console.log('Signup acc Failed');
             setIsLoading(false);
             setNext(false);
             setAlready(true);
             setError('You cant create multiple accounts');
             }
           } )
           .catch(err => console.log(err));
          });
          
        }
      })
    
      .catch(err => console.log(err));
    }
    }
  }
  console.log('ID:'+ isLogin);
  console.log(error)
  return (
    // <div className='login-container'>
    //   <div className="view-container">
    //   {isLoading && (
    //     <div style={{marginTop:'9rem'}} className="card-container">
    //       <div style={{marginBottom:'9rem'}} className="top-shape"></div>
    //             <CircularProgress color="primary" />
    //             <div style={{marginTop:'9rem'}} className="bottom-shape"></div>      
    //             </div>
    //         )}
    //   {!isLoading &&  ( isLogin? (
    //     <div className='temp-div'>
    //       <h3>App is under maintenance</h3>
    //       <button onClick={handlelogout}>Logout</button>
    //     </div>
    //   ):(
    //             <div style={account ? {marginTop:'9rem'}:{marginTop:'5rem'}} className="card-container">
    //             <div className="top-shape"></div>
    //             <img className='app-logo' src="logo192.png" alt="" />
    //           <h1 className="app-name">
    //               Salary Trader
    //           </h1>
    //           <div>
    //           <span className='dont-sub-text'>
    //             {account ?("Don't have an account yet?"):("Already have an account?")}
                
    //           </span>
    //           <span onClick={handleclick} className='signup-text'>
    //           {account ?("SignUp"):("login")}
                
    //           </span>
      
    //           </div>
    //           <div className="input-div">
    //             <img className='input-img' src="email.png" alt="" />
    //             <input onChange={e => setEmail(e.target.value)} type="email" placeholder='Email' name="" id="" />
    //           </div>
    //           <div className="input-div">
    //             <img className='input-img' src="padlock.png" alt="" />
    //             <input onChange={e => setPassword(e.target.value)} type="password" placeholder='Password' name="" id="" />
    //           </div>
    //           <div style={account ? {display:'none'}:{display:'flex'}} className="input-div">
    //             <img className='input-img' src="padlock.png" alt="" />
    //             <input onChange={e => setConfPassword(e.target.value)} type="password" placeholder='Confirm Password' name="" id="" />
    //           </div>
    //           <div style={account ? {display:'none'}:{display:'flex'}} className="input-div">
    //             <img className='input-img' src="user.png" alt="" />
    //             <input onChange={e => setFullName(e.target.value)} type="name" placeholder='Fullname' name="" id="" />
    //           </div>
    //           <div style={account ? {display:'none'}:{display:'flex'}} className="input-div">
    //             <img className='input-img' src="countries.png" alt="" />
    //             <select onChange={e => setSelectedCountry(e.target.value)} value={selectedCountry}>
    //                       <option value="">Select Country</option>
    //                       {countriesList.map(country => (
    //                         <option key={country} value={country}>{country}</option>
    //                       ))}
    //                     </select>
    //           </div>
      
    //           <div style={already ? {display:'flex'}:{display:'none'}} onClick={handleSubmit} className="error-div">
    //             <img src="https://cdn-icons-png.flaticon.com/128/4201/4201973.png" alt="" />
    //             <span>{error}</span>
    //           {/* {account ?("Login"):("Sign Up")} */}
    //           </div>
      
    //           <button onClick={handleSubmit} className="signup-btn">
    //           {account ?("Login"):("Sign Up")}
    //           </button>
    //           <div className="bottom-shape"></div>
      
    //           </div>
    //         ))}      
      
        
    //   </div>
    // </div>
    <div className='login-container' style={visible !== 'true' ? {display:'none'}:{display:'flex'}}>
      <div className="view-container">
      {!isLoading &&  ( !isLogin? (
        <div className="login-grad-out" >
        <div className="login-grad" >
          
          <span>SALARY TRADER</span>
          {/* <div id="otpless-login-page"></div> */}
          <div className="in-login-grad">
            <h2>{account ?("Welcome back to"):("Welcome to")} <br></br> <strong style={{fontFamily:'Bold'}}>Salary Trader</strong></h2>
          </div>

          
        </div>
        </div>):(null))}
      {isLoading && (
        <div style={{justifyContent:'center',alignItems:'center'}} className="card-container">
                <CircularProgress color="primary" />    
                </div>
            )}
      {!isLoading &&  ( isLogin? (
        <div className='temp-div'>
          <h2>Registered</h2>
          <h3>You are in queue , we will accept your request soon . Please be patient</h3>
          <button onClick={handlelogout}>Logout</button>
        </div>
      ):(
                <div className="card-container" style={account ? {height:'30.4rem'}:{height:'auto'}}>
              <h1 className="app-name">
              {!account ?("SignUp"):("Login")}
              </h1>
              <span className="dont-sub-text">
                {/* Create a account to access Trading panel and start trading */}
              </span>
              
              <div style={account ? {display:'none'}:{display:'flex'}} className="input-div">
                <img className='input-img' src="id-card.png" alt="" />
                <input onChange={e => setFName(e.target.value)} type="name" placeholder='First Name' name="" id="" />
              </div>
              <div style={account ? {display:'none'}:{display:'flex'}} className="input-div">
                <img className='input-img' src="id-card.png" alt="" />
                <input onChange={e => setLName(e.target.value)} type="name" placeholder='Last Name' name="" id="" />
              </div>
              <div className="input-div">
                <img className='input-img' src="email.png" alt="" />
                <input onChange={e => setEmail(e.target.value)} type="email" placeholder='Email' name="" id="" />
              </div>
              <div style={account ? {display:'none'}:{display:'flex'}} className="input-div">
                <img className='input-img' src="smartphone.png" alt="" />
                <input onChange={e => setCode(e.target.value)} style={{width:'22%'}} type="text" defaultValue={'+91'} />
                <input onChange={e => setMobile(e.target.value)} type="number" placeholder='Mobile' name="" id="" />
                {/* <button onClick={isOtpDone?(null):(phoneAuth)} style={{backgroundColor:'#0172da',color:'white',padding:'5px',marginRight:'5px',borderRadius:'22px',width:'45%',fontSize:'10px',whiteSpace:'nowrap',overflow:'hidden',border:'none',paddingTop:'8px',paddingBottom:'7px',fontFamily:'All'}}>{isOtpSent? 'Resend':'Send OTP'}</button> */}
              </div>
              <div className="input-div" style={{width:'55%',display:isOtpSent?'flex':'none'}}>
                {/* <img className='input-img' src="padlock.png" alt="" /> */}
                <input onChange={e => setOtp(e.target.value)} type="password" placeholder='  OTP' name="" id="" />
                <button onClick={isOtpDone?(null):(verifyOTP)} style={{backgroundColor:'#0172da',color:'white',padding:'5px',marginRight:'5px',borderRadius:'22px',width:'45%',fontSize:'10px',whiteSpace:'nowrap',overflow:'hidden',border:'none',paddingTop:'8px',paddingBottom:'7px',fontFamily:'All'}}>{!isOtpFail?( (isOtpDone?'Verified':'Verify')):('ReVerify')}</button>
              </div>
              <div className="input-div">
                <img className='input-img' src="padlock.png" alt="" />
                <input onChange={e => setPassword(e.target.value)} type="password" placeholder='Password' name="" id="" />
              </div>
              <div style={account ? {display:'none'}:{display:'flex'}} className="input-div">
                <img className='input-img' src="padlock.png" alt="" />
                <input onChange={e => setConfPassword(e.target.value)} type="password" placeholder='Confirm Password' name="" id="" />
              </div>
              <div style={account ? {display:'none'}:{display:'flex'}} className="input-div">
                <img className='input-img' src="user.png" alt="" />
                <input onChange={e => setFullName(e.target.value)} type="name" placeholder='Username' name="" id="" />
              </div>
              <div style={account ? {display:'none'}:{display:'flex'}} className="input-div">
                <img className='input-img' src="location.png" alt="" />
                <input onChange={e => setCity(e.target.value)} type="text" placeholder='City' name="" id="" />
              </div>
              <div style={account ? {display:'none'}:{display:'flex'}} className="input-div">
                <img className='input-img' src="countries.png" alt="" />
                <select onChange={e => setSelectedCountry(e.target.value)} value={selectedCountry}>
                          <option value="">Select Country</option>
                          {countriesList.map(country => (
                            <option key={country} value={country}>{country}</option>
                          ))}
                        </select>
              </div>
              <div style={account ? {display:'none'}:{display:'flex'}} className="input-div">
                <img className='input-img' src="location.png" alt="" />
                <input onChange={e => setRefer(e.target.value)} type="text" placeholder='Referral Code (Optional)' name="" id="" />
              </div>
      
              <div style={already ? {display:'flex'}:{display:'none'}} onClick={handleSubmit} className="error-div">
                <img src="https://cdn-icons-png.flaticon.com/128/4201/4201973.png" alt="" />
                <span>{error}</span>
              {/* {account ?("Login"):("Sign Up")} */}
              </div>

              <div>
              <span className='dont-sub-text'>
                {account ?("Don't have an account yet?"):("Already have an account?")}
                
              </span>
              <span onClick={handleclick} className='signup-text'>
              {account ?("SignUp"):("login")}
                
              </span>
      
              </div>
      

              <button onClick={handleSubmit} className="signup-btn">
              {account ?("Login"):("Sign Up")}
              </button>
      
              </div>
            ))}      
      
        
      </div>
    </div>
  )
}
