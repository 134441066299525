// TradingViewWidget.jsx
import React, { useEffect, useRef, memo } from 'react';

function TradingViewWidget({isSidebar}) {
  const isMobile = /Mobi|Android/i.test(navigator.userAgent);
  const container = useRef();
  const [interval,setInterval] = React.useState(1);
  function selectInterval(e) {
    document.getElementById(1).style.backgroundColor = "transparent";
    document.getElementById(5).style.backgroundColor = "transparent";
    document.getElementById(30).style.backgroundColor = "transparent";
    document.getElementById(60).style.backgroundColor = "transparent";
    document.getElementById('D').style.backgroundColor = "transparent";
    document.getElementById('W').style.backgroundColor = "transparent";
    e.target.style.backgroundColor = '#01365c'
    setInterval(e.target.id);
  }

  useEffect(() => {
    const currentContainer = container.current;

    const loadScript = () => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js';
      script.innerHTML = JSON.stringify({
        autosize: true,
        symbol: "OANDA:XAUUSD",
        interval: String(interval),
        timezone: "Etc/UTC",
        theme: "dark",
        style: "1",
        locale: "en",
        hide_top_toolbar: true,
        hide_legend: true,
        backgroundColor: "#0e0f13",
        allow_symbol_change: false,
        calendar: false,
        hide_volume: true,
        save_image: false,
        support_host: "https://www.tradingview.com"
      });

      if (currentContainer) {
        currentContainer.innerHTML = '';
        currentContainer.appendChild(script);
      }
    };

    const timeoutId = setTimeout(loadScript, 100); // Delay script loading by 100ms

    console.log(setInterval)
    return () => {
      clearTimeout(timeoutId); // Clear the timeout if the component unmounts
      if (currentContainer) {
        currentContainer.innerHTML = ''; // Cleanup on unmount
      }
    };
  }, [interval]);

  return (
    // <div style={{ width: window.screen.width > 600 ?(isSidebar? '70%':'52%') : '100%', height: window.screen.width > 600 ? '63%' : '44%', overflow: 'visible', marginBottom: '10px', border: 'none',transition:'all 1s ease' }}>
    <div style={{width: window.screen.width > 600 ?(isSidebar? '70%':'52%') : '100%', display:'flex',flexDirection:'column', overflow: 'visible', marginBottom: '0px', border: 'none',transition:'all 1s ease',paddingBottom: '0vh',marginLeft:window.screen.width > 600 ?'0px':(isMobile?'0%':'-20%'),height:window.screen.width > 600 ?'80vh':'50vh' }}>
      <div  className="intervals" style={{width: '100%',display:'flex',margin:'10px'}}>
        <div id='1' onClick={selectInterval} className="intervalbox" style={{borderRadius:'6px',backgroundColor:'#01365c',padding:'5px',border:'solid 1px #01365c',marginRight:'6px'}}>
          <span style={{color:'white',fontSize:'12px',fontFamily:'All',pointerEvents:'none'}}>1 Min</span>
        </div>
        <div id='5' onClick={selectInterval} className="intervalbox" style={{borderRadius:'6px',padding:'5px',border:'solid 1px #01365c',marginRight:'6px'}}>
          <span style={{color:'white',fontSize:'12px',fontFamily:'All',pointerEvents:'none'}}>5 Min</span>
        </div>
        <div id='30' onClick={selectInterval} className="intervalbox" style={{borderRadius:'6px',padding:'5px',border:'solid 1px #01365c',marginRight:'6px'}}>
          <span style={{color:'white',fontSize:'12px',fontFamily:'All',pointerEvents:'none'}}>30 Min</span>
        </div>
        <div id='60' onClick={selectInterval} className="intervalbox" style={{borderRadius:'6px',padding:'5px',border:'solid 1px #01365c',marginRight:'6px'}}>
          <span style={{color:'white',fontSize:'12px',fontFamily:'All',pointerEvents:'none'}}>1 Hr</span>
        </div>
        <div id='D' onClick={selectInterval} className="intervalbox" style={{borderRadius:'6px',padding:'5px',border:'solid 1px #01365c',marginRight:'6px'}}>
          <span style={{color:'white',fontSize:'12px',fontFamily:'All',pointerEvents:'none'}}>1 Day</span>
        </div>
        <div id='W' onClick={selectInterval} className="intervalbox" style={{borderRadius:'6px',padding:'5px',border:'solid 1px #01365c',marginRight:'6px'}}>
          <span style={{color:'white',fontSize:'12px',fontFamily:'All',pointerEvents:'none'}}>1 Week</span>
        </div>
        
      </div>
      <div className="tradingview-widget-container" ref={container} style={{ height: "100%", width: "100%", border: 'none' }}>
        <div className="tradingview-widget-container__widget" style={{ height: "calc(100% - 32px)", width: "100%", border: 'none' }}></div>
        {/* <div className="tradingview-widget-copyright"><a href="https://www.tradingview.com/" rel="noreferrer nofollow" target="_blank"><span className="blue-text">Track all markets on TradingView</span></a></div> */}
      </div>
    </div>
  );
}

export default memo(TradingViewWidget);
